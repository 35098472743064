import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [0,3,10,15,2];

export const dictionary = {
		"/": [~17],
		"/about-dubclub": [21],
		"/account": [~22],
		"/auth/complete/account": [23,[3]],
		"/auth/complete/social": [~24,[3]],
		"/auth/login": [25,[3]],
		"/auth/login/phone": [26,[3]],
		"/auth/verify": [27,[3]],
		"/auth/verify/phone": [28,[3]],
		"/automations/tracking-links": [~29],
		"/capper/affiliates/links": [30,[4]],
		"/capper/affiliates/members": [31,[4]],
		"/capper/affiliates/sales": [32,[4]],
		"/capper/dashboard": [33],
		"/capper/discord/custom-bot/manage": [34,[5]],
		"/capper/discord/custom-bot/setup": [~35,[5]],
		"/capper/discord/manage": [~36],
		"/capper/discord/setup": [~37],
		"/capper/integrations": [~38],
		"/capper/manage-prices": [~39,[6],[7]],
		"/capper/products": [~40],
		"/capper/profile/[name_or_price]": [~41],
		"/capper/publish": [~42],
		"/capper/referral": [43,[8]],
		"/capper/stream/[name]": [~44,[9]],
		"/checkout/[trackinterest_id]": [~45,[10]],
		"/footer": [46],
		"/plays/[textpickKey]": [~47],
		"/plays/[textpickKey]/[viewerKey]": [~48],
		"/privacy": [49],
		"/product/new": [50],
		"/publish/play/[capperKey]": [~51,[11]],
		"/publish/sales": [~52,[12]],
		"/referrals": [61],
		"/refund-policy": [62],
		"/r/capper": [53],
		"/r/connected-accounts": [~54],
		"/r/discover-cappers": [55,[13]],
		"/r/discover-cappers/(discovery)/cappers": [56,[13,14]],
		"/r/discover-cappers/(discovery)/leagues": [57,[13,14]],
		"/r/discover-cappers/(discovery)/plays": [58,[13,14]],
		"/r/discover-cappers/(discovery)/search": [59,[13,14]],
		"/r/discover-cappers/(discovery)/sports": [60,[13,14]],
		"/samples/[sampleKey]": [~63],
		"/samples/[sampleKey]/[viewerKey]": [~64],
		"/subscriptions": [~65],
		"/terms-of-service": [71],
		"/t/engagement/[capperKey]": [~70],
		"/t/[productKey]": [66,[15]],
		"/t/[productKey]/edit": [67],
		"/t/[productKey]/faqs": [~68,[15]],
		"/t/[productKey]/notifications": [69,[15]],
		"/upgrade/[subKey]": [~72],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~73,[16]],
		"/[profileName]": [~18],
		"/[profileName]/edit": [~19,[2]],
		"/[profileName]/edit/sections": [~20,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';